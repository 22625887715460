import React, { Fragment } from 'react';
//import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { saasClassicTheme } from '../common/theme/index';
import ResetCSS from '../common/style';
import { GlobalStyle, ContentWrapper } from '../components/aiexpertsedge.style';
import { DrawerProvider } from '../common/contexts/DrawerContext';
import BannerSection from '../components/Banner/index';
import Navbar from '../components/Navbar';
import FeatureSection from '../components/Feature';
import TestimonialSection from '../components/Testimonial';
import PricingSection from '../components/Pricing';
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';
import Disclaimer from '../components/Disclaimer';
import { Helmet } from 'react-helmet';
import { useWindowSize } from 'react-use';

const AiExpertsEdgeLandingPage = () => {

  const { width, height } = useWindowSize();

  console.log( 'height', height )

  return (
    <ThemeProvider theme={ saasClassicTheme }>
      <Fragment>
        {/*<Head>
          <title>AiExpertsEdgeLandingPage | A react next landing page</title>
          <meta name="Description" content="React next landing page" />
          <meta name="theme-color" content="#ec5555" />

          <link href="https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700" rel="stylesheet" />
        </Head>*/}
        <Helmet>
          <title>AI Experts Edge</title>
          <meta name="description" content="AI Experts Edge | Is Management Career Right For You?" />
        </Helmet>
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={ height - 70 } innerZ={ 9999 } activeClass="sticky-nav-active">
            {/*<DrawerProvider>
              <Navbar />
            </DrawerProvider>*/}
            <Navbar />
          </Sticky>
          <BannerSection />
          <FeatureSection />
          <TestimonialSection />
          <PricingSection />
          <ContactUs />
          <Disclaimer />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default AiExpertsEdgeLandingPage;
