import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import Fade from 'react-reveal/Fade';
import { openModal, closeModal } from '@redq/reuse-modal';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';
import Container from '../../common/components/UI/Container';
import TiltShape from '../TiltShape';
import {
  BannerWrapper,
  DiscountWrapper,
  DiscountLabel,
  VideoModal,
} from './banner.style';
import BannerImage from '../../common/assets/banner_image.png';

import logo from '../../common/assets/ai_experts_logo.png';


const BannerSection = ( {
  row,
  contentWrapper,
  discountAmount,
  discountText,
  title,
  description,
  descriptionTwo,
  imageWrapper,
  buttonWrapper,
  button,
  fillButton,
} ) => {
  // modal handler

  const navigateToTest = () => {
    window.open('http://survey.aiexpertsedge.com/aiee/203', '_blank');
  };

  return (
    <BannerWrapper id="banner_section">
      <TiltShape className="banner-shape" />
      <Container>
        <Box { ...row }>
          <Box { ...contentWrapper }>
            <DiscountWrapper>
              <DiscountLabel>
                <img src={ logo } style={ { width: '110px', height: '62px' } } />
              </DiscountLabel>
            </DiscountWrapper>
            <Heading
              { ...title }
              content="Unlock Your Management Aspirations: Are You MBA Material?"
            />
            <Text
              { ...descriptionTwo }
              content="Dive into our quick, enlightening quiz designed for future leaders. Discover if an MBA aligns with your career aspirations and uncover the manager within you. Ideal for graduates and professionals poised for success."
            />
            <Box { ...buttonWrapper }>
              <Button { ...fillButton } title="Download Your Free Report" onClick={ navigateToTest } />
            </Box>
          </Box>
          <Box { ...imageWrapper }>
            <Fade bottom>
              <VideoModal>
                <img src={ BannerImage } alt="banner image" />
              </VideoModal>
            </Fade>
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  contentWrapper: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  descriptionTwo: PropTypes.object,
  imageWrapper: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
  fillButton: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrapper: {
    width: ['100%', '100%', '80%', '55%', '50%'],
    mb: '40px',
  },
  title: {
    fontSize: ['24px', '32px', '40px', '42px', '40px'],
    mb: ['20px', '20px', '20px', '20px', '20px'],
    fontWeight: '700',
    color: '#fff',
    letterSpacing: '-0.025em',
    lineHeight: '1.2',
    textAlign: 'center',
  },
  description: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    mb: ['20px', '25px', '25px', '25px', '25px'],
    color: '#edcd37',
    lineHeight: '1.75',
    textAlign: 'center',
    fontWeight: '800',
  },
  descriptionTwo: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: '#fff',
    lineHeight: '1.75',
    mb: '0',
    textAlign: 'center',
  },
  discountAmount: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    fontWeight: '700',
    color: '#fff',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  discountText: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    fontWeight: '400',
    color: '#fff',
    mb: 0,
    as: 'span',
  },
  fillButton: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '800',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    colors: 'secondaryWithBg',
    height: '46px',
    minWidth: ['auto', '150px'],
    height: ['40px', '46px'],
    minHeight: 'auto',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: '35px',
  },
  button: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    color: '#fff',
    colors: 'secondary',
    height: ['40px', '46px'],
    minHeight: 'auto',
  },
};

export default BannerSection;
