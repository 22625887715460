import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Container from '../../common/components/UI/Container';
import GlideCarousel from '../../common/components/GlideCarousel';
import GlideSlide from '../../common/components/GlideCarousel/glideSlide';
import { TESTIMONIALS } from '../../data/index';
import aboutTheTestImage from '../../common/assets/OBJECTS.png';
import {
  TestimonialItem,
  WrapperRow,
  ImageWrapper,
} from './testimonial.style';

const TestimonialSection = ( {
  sectionWrapper,
  reviewTitle,
  review,
  sectionWrapperRow,
  image,
  sectionWrapperCol,
} ) => {

  return (
    <Box { ...sectionWrapper } as="section" id="testimonial_section">
      <Container>
        <WrapperRow>
          <>
            {/*{ TESTIMONIALS.map( ( item, index ) => (
              <Box { ...sectionWrapperCol } key={ `testimonial-slide-${ index }` }>
                <TestimonialItem>
                  <Heading as="h3" content={ item.title } { ...reviewTitle } />
                  <Text content={ item.review } { ...review } />
                </TestimonialItem>
              </Box>
            ) ) }*/}
            <Box { ...sectionWrapperCol } key={ `testimonial-slide-1` }>
              <TestimonialItem>
                <Heading as="h3" content='About The Test' { ...reviewTitle } />
                <Text content=' Your gateway to uncovering your potential in the dynamic world of management in 5 minutes.
The test offers a series of thought-provoking questions and scenarios, meticulously crafted to evaluate essential managerial competencies such as your communication skills, strategic thinking abilities, and leadership qualities.' { ...review } />
                <ImageWrapper>
                  <img src={ aboutTheTestImage } />
                </ImageWrapper>
                {/*<Text content={ <><img src={ aboutTheTestImage } style={ { width: '100px', height: '100px' } } /></> } { ...review } />*/}
              </TestimonialItem>
            </Box>
            <Box { ...sectionWrapperCol } key={ `testimonial-slide-2` }>
              <TestimonialItem>
                <Heading as="h3" content='Who is This Test for?' { ...reviewTitle } />
                <Text content={ <><strong> • Aspiring Managers :</strong> For those dreaming of leading teams and driving business success.<br /> <br /></> } { ...review } />
                <Text content={ <><strong> • Career Changers :</strong> Considering a switch to management? This test will guide your decision.<br /> <br /></> } { ...review } />
                <Text content={ <><strong> • Recent Graduates :</strong> Fresh out of college and pondering a management career? Start here.<br /> <br /></> } { ...review } />
                <Text content={ <><strong> • Entrepreneurial Minds :</strong> If you're planning to start your own business, assess your management acumen.<br /> <br /></> } { ...review } />
                <Text content={ <><strong> • Professionals Seeking Growth :</strong> Elevate your career by understanding your potential in management roles.<br /> <br /></> } { ...review } />
                <Text content={ <><strong> • Curious Minds :</strong> Anyone curious about what a management career entails.</> } { ...review } />
              </TestimonialItem>
            </Box>

          </>
        </WrapperRow>
      </Container>
    </Box>
  );
};

TestimonialSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionWrapperRow: PropTypes.object,
  sectionWrapperCol: PropTypes.object,
  image: PropTypes.object,
};

TestimonialSection.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '90px', '100px', '0px'],
    pb: ['60px', '80px', '90px', '100px', '60px'],
  },
  image: {
    pt: ['5px', '10px', '20px', '20px', '20px'],
  },
  sectionWrapperRow: {
    display: 'flex',
    flexwrap: 'wrap',
    ml: '-20px',
    mr: '-20px',
  },
  secTitleWrapper: {
    mb: ['40px', '60px'],
  },
  sectionWrapperCol: {
    flex: '1 1 33.333%',
    pl: '20px',
    pr: '20px',
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#ff4362',
    mb: '12px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px', '36px', '36px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    ml: 'auto',
    mr: 'auto',
    lineHeight: '1.12',
    width: '500px',
    maxWidth: '100%',
  },
  reviewTitle: {
    fontSize: ['15px', '18px'],
    fontWeight: '700',
    color: '#283972',
    lineHeight: '1.5',
    mb: '20px',
  },
  review: {
    fontSize: ['16px', '19px'],
    fontWeight: '300',
    color: '#343d48',
    lineHeight: '1.7',
    mb: 0,
  },
  name: {
    fontSize: ['14px', '16px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '8px',
  },
  designation: {
    fontSize: ['12px', '14px'],
    color: '#6f7a87',
    mb: 0,
  },
};

export default TestimonialSection;
