//import Screenshot1 from '../../assets/image/saasClassic/screen-1.png';

import AuthorOne from '../common/assets/author-1.jpg';
import AuthorTwo from '../common/assets/author-2.jpg';
import testiImage from '../common/assets/aboutthetest2.png';
import step1 from '../common/assets/step1.png';
import step2 from '../common/assets/step2.png';
import step3 from '../common/assets/step3.png';
import feature_icon_one from '../common/assets/feature_icon_1.png';
import feature_icon_two from '../common/assets/feature_icon_2.png';
import feature_icon_three from '../common/assets/feature_icon_3.png';
//import AuthorThree from '../../assets/image/saasClassic/author-3.jpg';

import { ic_monetization_on } from 'react-icons-kit/md/ic_monetization_on';
import { ic_settings } from 'react-icons-kit/md/ic_settings';
import { pieChart } from 'react-icons-kit/icomoon/pieChart';
import { briefcase } from 'react-icons-kit/fa/briefcase';
import aiExpertsLogo from '../common/assets/ai_experts_logo.png'

export const FOOTER_DATA = {
  logo: aiExpertsLogo,
  menu: [
    {
      link: '#',
      label: 'Support',
    },
    {
      link: '#',
      label: 'About Us',
    },
    {
      link: '#',
      label: 'Privacy',
    },
    {
      link: '#',
      label: 'Contact',
    },
  ],
  social: [
    {
      icon: aiExpertsLogo,
      link: '#',
    },
  ],
};


//export const MENU_ITEMS = [{
//        label: 'Home',
//        path: '#banner_section',
//        offset: '0',
//    },
//    {
//        label: 'Screenshot',
//        path: '#screenshot_section',
//        offset: '0',
//    },
//    {
//        label: 'Feature',
//        path: '#feature_section',
//        offset: '0',
//    },
//    {
//        label: 'Pricing',
//        path: '#pricing_section',
//        offset: '0',
//    },
//    {
//        label: 'Testimonial',
//        path: '#testimonial_section',
//        offset: '0',
//    },
//];

//export const SERVICE_ITEMS = [{
//        icon: 'flaticon-stopwatch-1',
//        title: 'Fast Performance',
//    },
//    {
//        icon: 'flaticon-prototype',
//        title: 'Prototyping',
//    },
//    {
//        icon: 'flaticon-code',
//        title: 'Coade Export',
//    },
//    {
//        icon: 'flaticon-vectors',
//        title: 'Vector Editing',
//    },
//    {
//        icon: 'flaticon-export',
//        title: 'Export Presets',
//    },
//];

export const MONTHLY_PRICING_TABLE = [{
  name: 'Step 1',
  description: 'Take the Test',
  price: (
    <>
      Begin your journey by taking our meticulously designed test. Crafted to fit into a short span of 5-7 minutes,
      this test includes a series of questions and scenarios aimed at assessing key skills essential for a successful career in management.
      <br /><br />
      <img src={ step1 } />
    </>
  ),
},
{
  name: 'Step 2',
  description: 'Receive your personalized Report',
  price: (
    <>
      Upon completion, you'll receive a comprehensive report that analyzes your responses. This report will provide insights
      into your strengths and areas for improvement, offering a clear perspective on your compatibility with a career in management.
      <br /><br />
      <img src={ step2 } />
    </>
  ),
},
{
  name: 'Step 3',
  description: 'Next Steps & Recommendation',
  price: (
    <>
      Let your test results guide you towards making this crucial academic and career choice. Leverage the valuable insights gained
      from your detailed report to make an informed and wise decision about pursuing further management studies.
      <br /><br />
      <img src={ step3 } />
    </>
  ),
},
];

export const YEARLY_PRICING_TABLE = [{
  name: 'Basic Account',
  description: 'For a single client or team who need to build website ',
  price: '$0',
  priceLabel: 'Only for first month',
  buttonLabel: 'Start for free',
  url: '#',
  listItems: [{
    content: 'Drag & Drop Builder',
  },
  {
    content: '1,000s of Templates Ready',
  },
  {
    content: 'Blog Tools',
  },
  {
    content: 'eCommerce Store ',
  },
  {
    content: '30+ Webmaster Tools',
  },
  ],
},
{
  name: 'Business Account',
  description: 'For Small teams or group who need to build website ',
  price: '$6.00',
  priceLabel: 'Per month & subscription yearly',
  buttonLabel: 'Register Now',
  url: '#',
  trialButtonLabel: 'Or Start 14 Days trail',
  trialURL: '#',
  listItems: [{
    content: 'Unlimited secure storage',
  },
  {
    content: '2,000s of Templates Ready',
  },
  {
    content: 'Blog Tools',
  },
  {
    content: '24/7 phone support',
  },
  {
    content: '50+ Webmaster Tools',
  },
  ],
},
{
  name: 'Premium Account',
  description: 'For Large teams or group who need to build website ',
  price: '$9.99',
  priceLabel: 'Per month & subscription yearly',
  buttonLabel: 'Register Now',
  url: '#',
  trialButtonLabel: 'Or Start 14 Days trail',
  trialURL: '#',
  listItems: [{
    content: 'Drag & Drop Builder',
  },
  {
    content: '3,000s of Templates Ready',
  },
  {
    content: 'Advanced branding',
  },
  {
    content: 'Knowledge base support',
  },
  {
    content: '80+ Webmaster Tools',
  },
  ],
},
];

//export const FAQ_DATA = [{
//        expend: true,
//        title: 'How to contact with Customer Service?',
//        description: 'Our Customer Experience Team is available 7 days a week and we offer 2 ways to get in contact.Email and Chat . We try to reply quickly, so you need not to wait too long for a response!. ',
//    },
//    {
//        title: 'App installation failed, how to update system information?',
//        description: 'Please read the documentation carefully . We also have some online  video tutorials regarding this issue . If the problem remains, Please Open a ticket in the support forum . ',
//    },
//    {
//        title: 'Website reponse taking time, how to improve?',
//        description: 'At first, Please check your internet connection . We also have some online  video tutorials regarding this issue . If the problem remains, Please Open a ticket in the support forum .',
//    },
//    {
//        title: 'New update fixed all bug and issues?',
//        description: 'We are giving the update of this theme continuously . You will receive an email Notification when we push an update. Always try to be updated with us .',
//    },
//];

//export const FOOTER_WIDGET = [{
//        title: 'About Us',
//        menuItems: [{
//                url: '#',
//                text: 'Support Center',
//            },
//            {
//                url: '#',
//                text: 'Customer Support',
//            },
//            {
//                url: '#',
//                text: 'About Us',
//            },
//            {
//                url: '#',
//                text: 'Copyright',
//            },
//            {
//                url: '#',
//                text: 'Popular Campaign',
//            },
//        ],
//    },
//    {
//        title: 'Our Information',
//        menuItems: [{
//                url: '#',
//                text: 'Return Policy',
//            },
//            {
//                url: '#',
//                text: 'Privacy Policy',
//            },
//            {
//                url: '#',
//                text: 'Terms & Conditions',
//            },
//            {
//                url: '#',
//                text: 'Site Map',
//            },
//            {
//                url: '#',
//                text: 'Store Hours',
//            },
//        ],
//    },
//    {
//        title: 'My Account',
//        menuItems: [{
//                url: '#',
//                text: 'Press inquiries',
//            },
//            {
//                url: '#',
//                text: 'Social media directories',
//            },
//            {
//                url: '#',
//                text: 'Images & B-roll',
//            },
//            {
//                url: '#',
//                text: 'Permissions',
//            },
//            {
//                url: '#',
//                text: 'Speaker requests',
//            },
//        ],
//    },
//    {
//        title: 'Policy',
//        menuItems: [{
//                url: '#',
//                text: 'Application security',
//            },
//            {
//                url: '#',
//                text: 'Software principles',
//            },
//            {
//                url: '#',
//                text: 'Unwanted software policy',
//            },
//            {
//                url: '#',
//                text: 'Responsible supply chain',
//            },
//        ],
//    },
//];

export const FEATURES = [{
  icon: feature_icon_one,
  title: 'Understand Your Potential',
  description: 'This test illuminates your intrinsic management skills and talents, helping you understand your unique strengths and areas for development.',
},
{
  icon: feature_icon_two,
  title: 'Clarify Your Career Path',
  description: 'The test offers clarity on how well a management career aligns with your personal goals and existing skill set.',
},
{
  icon: feature_icon_three,
  title: 'Empower your choice',
  description: 'Armed with knowledge about your management potential, you can confidently decide whether to pursue a career in this dynamic field.',
},
];

//export const SCREENSHOTS = [{
//        icon: ic_monetization_on,
//        title: 'Budget Overview',
//        image: Screenshot1,
//    },
//    {
//        icon: ic_settings,
//        title: 'Create & Adjust',
//        image: Screenshot1,
//    },
//    {
//        icon: pieChart,
//        title: 'View Reports',
//        image: Screenshot1,
//    },
//    {
//        icon: briefcase,
//        title: 'Integrations',
//        image: Screenshot1,
//    },
//];

export const TESTIMONIALS = [
  {
    title: 'About The Test',
    review: (
      <>
        <br /><br /> <br />
        {/*<img style={ { paddingLeft: '60px', paddingTop: '15px' } } src={ testiImage } alt="Testimonial Image" />*/ }
        <strong>• Text Format : </strong> 15 questions <br /> <br />
        <strong>• Question Format : </strong> MCQ <br /> <br />
        <strong>• Duration : </strong> 5 Minutes <br /> <br />
        <strong>• Level :</strong> Begineer as well as Intermediate<br /> <br />
        <br /> <br /><br />
        The test offers a series of thought-provoking questions, crafted to evaluate essential competencies such as your communication skills,
        strategic thinking abilities, and leadership qualities.
      </>
    ),
    name: 'Jon Doe',
    designation: 'CEO of Dell Co.',
    avatar: AuthorOne,
  },
  {
    title: 'Who is This Test for?',
    review: (
      <>
        <strong>• Aspiring Managers:</strong> For those dreaming of leading teams and driving business success.<br /> <br />
        <strong>• Career Changers:</strong> Considering a switch to management? This test will guide your decision.<br /> <br />
        <strong>• Recent Graduates:</strong> Fresh out of college and pondering a management career? Start here.<br /> <br />
        <strong>• Entrepreneurial Minds:</strong>If you're planning to start your own business, assess your management acumen.<br /> <br />
        <strong>• Professionals Seeking Growth:</strong>Elevate your career by understanding your potential in management roles.<br /> <br />
        <strong>• Curious Minds:</strong>Anyone curious about what a management career entails.
      </>
    ),
    name: 'Jon Doe',
    designation: 'Co Founder of IBM',
    avatar: AuthorTwo,
  },
];
